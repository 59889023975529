<template>
  <Modal v-model="showPushManage"
        class-name="vertical-center-modal"
        footer-hide
         @on-cancel="cancelHandle"
        :width="width">
    <p slot="header"
       style="font-size: 18px;font-weight: bold;color: #333333;"
       class="modalHeader">
        我的推送
    </p>
    <div class="modalContent">
        <div class="part top">
            <div class="part-title">条件选择</div>
            <div class="line">
                <div class="title">选择地区：</div>
                <div class="des">
                    <Select v-model="formItem.province" style="width:100px" size="small" placeholder="省份"
                        @on-change="selectProvince">
                        <Option v-for="item in landProvinceList" :value="item.areaId" :key="item.areaId">{{ item.name }}</Option>
                    </Select>
                    <Select v-model="formItem.city" style="width:100px" size="small" placeholder="城市"
                        @on-change="selectCity">
                        <Option v-for="item in cityList" :value="item.areaId" :key="item.areaId">{{ item.name }}</Option>
                    </Select>
                    <Select v-model="formItem.region" style="width:100px" size="small" placeholder="行政区">
                        <Option v-for="item in regionList" :value="item.areaId" :key="item.areaId">{{ item.name }}</Option>
                    </Select>
                </div>
            </div>
            <div class="line">
                <div class="title">出让方式：</div>
                <div class="des">
                    <Checkbox :value="checkSellAll"
                        @click.prevent.native="handleCheckAll(checkSellAll,sellWay,'sellWay')">全选</Checkbox>
                    <CheckboxGroup v-model="formItem.sellWay">
                        <Checkbox v-for="(item,index) in sellWay" :key="index" :label="item.id">{{item.name}}</Checkbox>
                    </CheckboxGroup>
                </div>
            </div>
            <div class="line">
                <div class="title">土地用途：</div>
                <div class="des">
                    <Checkbox :value="checkLandAll"
                        @click.prevent.native="handleCheckAll(checkLandAll,landUse,'landUse')">全选</Checkbox>
                    <CheckboxGroup v-model="formItem.landUse">
                        <Checkbox v-for="(item,index) in landUse" :key="index" :label="item.id">{{item.name}}</Checkbox>
                    </CheckboxGroup>
                </div>
            </div>
            <div class="line">
                <div class="title">受让单位：</div>
                <div class="des">
                    <!-- <Select v-model="formItem.enterprise" filterable>
                        <Option v-for="item in enterprise" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select> -->
                </div>
            </div>
        </div>
        <div class="choose-box">
            <div class="title">已选条件：</div>
            <div class="ct">
                <Tag type="border" closable>
                    广州: <span class="tabContent">住宅用地</span>
                </Tag>
            </div>
            <div class="add-btn" @click="addPush">添加</div>
        </div>
        <div class="part bottom">
            <div class="part-title">推送列表</div>
            <div class="line">
                <div class="title">推送1</div>
                <div class="des">
					<Tag type="border" closable>
						广州: <span class="tabContent">住宅用地</span>
					</Tag>
                </div>
            </div>
        </div>
    </div>
  </Modal>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import { getLandSingleSelectCity, getLandSingleSelectRegion } from '../../api/public'
export default {
    name: 'pushManage',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number,
            default: 712
        }
    },
    data () {
        return {
            cityList : [],
            regionList : [],

            formItem: {
                province : "",
                city : "",
                region : "",

                sellWay : [],
                landUse : [],
            },
        }
    },
    computed : {
        ...mapState([
            "landUse",
            "sellWay",
            "enterprise",
            "landProvinceList",
        ]),
		checkSellAll() {
			return this.formItem.sellWay.length == this.sellWay.length;
		},
		checkLandAll() {
			return this.formItem.landUse.length == this.landUse.length;
		},
        showPushManage: {
            get () {
                return this.$store.state.modal.showPushManage;
            },
            set (newValue) {
                this.$store.state.modal.showPushManage = newValue;
            }
        },
    },
    methods: {
		// 全选checkbox用
		handleCheckAll(val,list,key) {
			let item = this.formItem;
			// 已经全选
			if(val) {
				item[key] = [];
			} else {
				item[key] = list.map(item => {
					return item.id;
				});
			}
        },

        selectProvince(item) {
			getLandSingleSelectCity({
				id: item
			}).then(data => {
				this.cityList = data;
				this.formItem.city = "";
				this.regionList = [];
				this.formItem.region = "";
			})
        },
        selectCity(item) {
			getLandSingleSelectRegion({
				id: item
			}).then(data => {
				this.regionList = data;
				this.formItem.region = "";
			})
        },
        addPush() {

        },
        cancelHandle() {
            this.showPushManage = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/style/common.scss';

.vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    .ivu-modal {
        top: 0;
    }
    .ivu-modal-header {
        border: none;
    }
}
.modalHeader {
    position: relative;
    text-indent: 10px;
    &::after {
        content: "";
        position: absolute;
        width: 4px;
        height: 20px;
        background: rgba(0, 182, 255, 1);
        top: 0;
        left: 0;
    }
}
.modalContent {
    @include flex;
    flex-direction: column;
    // padding: 20px 20px 17px;
    font-size: 14px;
    .ivu-checkbox-group {
        display: inline-block;
    }
    .part {
        position: relative;
        width: 100%;
        padding: 25px 0 28px 28px;
        border-radius: 1px;
        border: 1px solid #E0E8ED;
        &-title {
            position: absolute;
            top: -10px;
            left: 28px;
            color: #333;
            font-weight: bold;
            background-color: #fff;
        }
        .line {
            @include flex(flex-start);
            .title {
                color: #666;
            }
            .des {
                .ivu-select {
                    margin-right: 10px;
                }
            }
        }
        &.top {
            .line {
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .choose-box {
        @include flex(flex-start);
        width: 100%;
        margin: 20px 0;
        .title {
            color: #666;
        }
        .ct {
            flex-grow: 1;
        }
        .add-btn {
            @include flex;
            color: #fff;
            width:47px;
            height:26px;
            background:rgba(0,182,255,1);
            border-radius:5px;
            cursor: pointer;
        }
    }
}
</style>
