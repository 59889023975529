<template>
    <div class="btn" :class="{'active':item.star}"  >
        <div class="icon" @click="click"></div>
        <span @click="txtClick">{{txt}}</span>
    </div>
</template>
<script>
export default {
    name : "tableBtn",
    props : {
        txt : {
            type : String,
            default : "看项目"
        },
        iconType : {
            type : String,
            default : "ios-star-outline"
        },
        item : {
            type: Object,
            default() {
                return {};
            }
        }
    },
    methods : {
        click() {
            this.$emit("click",this.item);
        },
        txtClick() {
            this.$emit("txtClick",this.item);
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";
.btn {
    @include flex;
    line-height: normal;
    white-space: nowrap;
    color: #2d8cf0;
    cursor: pointer;

    .ivu-icon {
        cursor: pointer;
    }
    .icon {
        @include bgSrc('no-star.png');
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        margin-right: 2px;
    }
    &.active {
        .icon {
            @include bgSrc('star.png');
        }
    }
}
</style>