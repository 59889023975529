<template>
	<Modal class="modal-map" footer-hide draggable fullscreen :width="100" v-model="showMapModal"
		:styles="{ top: '0px' }" class-name="vertical-center-modal">
		<div class="map-container" id="modalMap"></div>
		<div class="btn-box">
			<div :class="['btns', 'btn-location', { active: isLocating }]" @click="locate" title="重新定位"></div>
			<div :class="['btns', 'btn-weixing', { active: mapType === 1 }]" @click="changeMapType"
				:title="mapType === 1 ? '默认地图' : '卫星地图'"></div>
			<Button type="primary" class="btn btn-sure confirm" @click="confirm" size="large">确定</Button>
			<div class="btns btn-reset" @click="clear" title="重置"></div>
		</div>
	</Modal>
</template>

<script>
	import BMap from "BMap";
	import BMapLib from "BMapLib";
	import {
		lchown
	} from "fs";
	import {
		addCityProject,
		addPlaceProject,
		getXmid,
	} from "@/api/projectAnalysis";

	export default {
		name: "mapModal",
		props: {
			id: [String, Number],
			address: [String, Object],
			city: String,
			projectName: String,
			type: {
				type: String,
				default: "analysis",
			},
		},
		data() {
			return {
				mapData: {
					zoom: 18,
					center: {
						lng: 116.46,
						lat: 39.92,
					},
				},
				isMapReady: false,

				polygonList: [],
				polygonLabelList: [],
				polygonIndex: 0,
				drawingManager: null,

				pathData: null,
				point: null,
				mapType: 0,
				isLocating: false,
				overlayCloseIcon: require("@/assets/image/map/overLay_close.svg"),
				map:''
			};
		},
		computed: {
			showMapModal: {
				get() {
					return this.$store.state.modal.showMapModal;
				},
				set(newValue) {
					this.$store.state.modal.showMapModal = newValue;
				},
			},
		},

		watch: {
			showMapModal(value) {
				if (value) {
					this.$nextTick(() => {
						this.loadMap(BMap);
					});
				}
			},
		},
		mounted() {},
		methods: {
			getPoint() {
				if (typeof this.address === "string") {
					const geo = new BMap.Geocoder();
					geo.getPoint(
						`${this.address}`,
						(point) => {
							if (point) {
								const marker = new BMap.Marker(point);
								this.map.centerAndZoom(point, 16);
								this.map.addOverlay(marker);
								this.point = point;
							}
						},
						`${this.city}市`
					);
				} else {
					const point = new BMap.Point(this.address.lng, this.address.lat);
					const marker = new BMap.Marker(point);
					this.map.addOverlay(marker);
					this.map.centerAndZoom(point, 16);
					this.point = point;
				}
			},
			loadMap() {
				this.map = new BMap.Map("modalMap");
				this.map.centerAndZoom(
					new BMap.Point(this.mapData.center.lng, this.mapData.center.lat),
					this.mapData.zoom
				);
				// 创建城市选择控件
				var cityControl = new BMap.CityListControl({
					// 控件的停靠位置（可选，默认左上角）
					anchor: BMAP_ANCHOR_TOP_LEFT,
					// 控件基于停靠位置的偏移量（可选）
					offset: new BMap.Size(10, 5)
				});
				// 将控件添加到地图上
				this.map.addControl(cityControl);
				this.map.enableScrollWheelZoom();
				this.isMapReady = true;
				this.getPoint();
				this.initDrawingManager();
				// this.locate();
			},
			// 初始化地图收手绘工具
			initDrawingManager() {
				const styleOptions = {
					strokeColor: "red", //边线颜色。
					fillColor: "", //填充颜色。当参数为空时，圆形将没有填充效果。
					strokeWeight: 3, //边线的宽度，以像素为单位。
					strokeOpacity: 1, //边线透明度，取值范围0 - 1。
					fillOpacity: 1, //填充的透明度，取值范围0 - 1。
					strokeStyle: "dashed", //边线的样式，solid或dashed。
				};

				this.drawingManager = new BMapLib.DrawingManager(this.map, {
					isOpen: false, //是否开启绘制模式
					enableDrawingTool: true, //是否显示工具栏
					drawingToolOptions: {
						offset: new BMap.Size(5, 5), //偏离值
						anchor: BMAP_ANCHOR_TOP_LEFT,
						drawingModes: [BMAP_DRAWING_POLYGON],
					},
					polygonOptions: styleOptions, //多边形的样式
				});
				$(".BMapLib_Drawing_panel .BMapLib_polygon").on(
					"click",
					this.measureArea
				);
			},

			measureArea() {
				const self = this;
				this.clear();
				this.drawingManager.setDrawingMode(BMAP_DRAWING_POLYGON);
				this.drawingManager.enableCalculate();
				this.drawingManager.addEventListener("overlaycomplete", measureFn);

				function measureFn(e) {
					if (e.calculate === null) {
						// self.$Message.warning("无法测量");
						self.$msg.success({
							text: "框选区域无效，请重新框选"
						});
						self.map.removeOverlay(e.overlay);
						self.map.removeOverlay(e.label);
						return;
					}

					// e.label.ba.innerHTML = `
					//             <span>区域面积：${e.calculate}m²</span>
					//             <img id="polygonClose_${self.polygonIndex}" style="width:13px;height:13px;margin-left:4px;margin-right:4px;vertical-align:text-top;cursor:pointer" src="${self.overlayCloseIcon}" />
					//         `;

					self.polygonList.push({
						index: self.polygonIndex,
						overlay: e.overlay,
					});
					self.polygonLabelList.push({
						index: self.polygonIndex,
						overlay: e.label,
					});

					$(`#polygonClose_${self.polygonIndex}`).click((e) => {
						const index = Number($(e.target).attr("id").match(/\d/)[0]);
						self.polygonList.some((item, idx) => {
							if (item.index === index) {
								self.map.removeOverlay(item.overlay);
								self.polygonList.splice(idx, 1);
								return true;
							}
						});
						self.polygonLabelList.some((item, idx) => {
							if (item.index === index) {
								self.map.removeOverlay(item.overlay);
								self.polygonLabelList.splice(idx, 1);
								return true;
							}
						});
					});

					self.pathData = e.overlay.getPath();
					self.polygonIndex++;
					self.drawingManager.removeEventListener("overlaycomplete", measureFn);
				}
			},

			getCenterPoint(path) {
				var x = 0.0;
				var y = 0.0;
				for (var i = 0; i < path.length; i++) {
					x = x + parseFloat(path[i].lng);
					y = y + parseFloat(path[i].lat);
				}
				x = x / path.length;
				y = y / path.length;

				return new BMap.Point(x, y);
			},

			confirm() {
				const overlays = this.map.getOverlays();

				// let pathNum = 0;

				// for (let i = 0; i < overlays.length; i++) {
				//   if (overlays[i].ba.tagName === "path") {
				//     pathNum++;
				//   }
				// }

				// if (pathNum > 1) {
				//   // this.$Message.warning("只允许划一个区域");
				//     this.$msg.success({text:'只允许框选一个区域'});
				//   return;
				// }

				if (
					this.pathData &&
					this.pathData.length > 0 &&
					this.pathData.length < 3
				) {
					// this.$Message.warning("区域无效");
					this.$msg.error({
						text: "区域无效"
					});
					return;
				}

				const params = {
					tdLpId: this.id,
					xmmc: this.projectName,
					zdyjson: JSON.stringify(this.pathData),
					jd: this.point.lng,
					wd: this.point.lat,
				};

				if (this.type === "city") {
					addCityProject(params).then((res) => {
						this.showMapModal = false;
						this.$router.replace({
							path: "/Empowerment/Analysis/Home",
							query: {
								id: res,
							},
						});
					});
				} else if (this.type === "place") {
					addPlaceProject(params).then((res) => {
						this.showMapModal = false;
						this.$router.replace({
							path: "/Empowerment/Analysis/Home",
							query: {
								id: res,
							},
						});
					});
				} else {
					this.$emit("confirm", this.pathData, this.point);
					this.showMapModal = false;
				}
			},

			clear() {
				this.polygonList.forEach((item) => {
					this.map.removeOverlay(item.overlay);
				});
				this.polygonList = [];
				this.polygonLabelList.forEach((item) => {
					this.map.removeOverlay(item.overlay);
				});
				this.polygonLabelList = [];
			},

			changeMapType() {
				if (this.mapType === 0) {
					this.mapType = 1;
					this.map.setMapType(BMAP_SATELLITE_MAP);
				} else {
					this.mapType = 0;
					this.map.setMapType(BMAP_NORMAL_MAP);
				}
			},

			locate() {
				const $map = $("#modalMap .BMap_mask");
				$map.css("cursor", "default");
				this.isLocating = true;
				const fn = (event) => {
					this.map.clearOverlays();
					const point = new BMap.Point(event.point.lng, event.point.lat);
					// this.map.setCenter(point);
					this.map.addOverlay(new BMap.Marker(point));

					this.point = point;
					this.isLocating = false;
					$map.css("cursor", "");
					this.map.removeEventListener("click", fn);
				};
				this.map.addEventListener("click", fn);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.modal-map {
		position: relative;

		.btn-box {
			position: absolute;
			width: 100%;
			top: 57px;
			right: 0px;

			.btns {
				position: absolute;
				width: 64px;
				height: 45px;
				border: 1px solid #666;
				cursor: pointer;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100% auto;
				border-radius: 5px;

				&.btn-location {
					left: 153px;
					background-image: url(../../assets/image/location.jpg);
					z-index: 99999;

					&:hover {
						background-image: url(../../assets/image/location_active.jpg);
					}

					&.active {
						background-image: url(../../assets/image/location_active.jpg);
					}
				}

				&.btn-weixing {
					left: 219px;
					background-image: url(../../assets/image/weixing.jpg);
					z-index: 999999;

					&:hover {
						background-image: url(../../assets/image/weixing_active.jpg);
					}

					&.active {
						background-image: url(../../assets/image/dimian.jpg);

						&:hover {
							background-image: url(../../assets/image/dimian_active.jpg);
						}
					}
				}

				&.btn-reset {
					right: 25px;
					background-image: url(../../assets/image/reset.jpg);

					&:hover {
						background-image: url(../../assets/image/reset_active.jpg);
					}

					&.active {
						background-image: url(../../assets/image/reset_active.jpg);
					}
				}
			}

			.btn-sure {
				left: 285px;
				position: absolute;
				cursor: pointer;
				width: 64px;
				height: 44px;
			}

			&.active {
				color: #2d8cf0;
				border-color: #2d8cf0;
			}
		}

		& /deep/ .vertical-center-modal {
			display: flex;
			align-items: center;
			justify-content: center;

			.ivu-modal {
				top: 0;
			}

			.ivu-modal-body {
				padding-top: 50px;
			}

			.map-container {
				width: 100%;
				height: 100%;
			}
		}
	}
</style>
