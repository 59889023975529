<template>
	<div class="page">
		<div class="page-ct">
			<div class="page-title">地块搜索</div>

			<div class="search-box">
				<div class="left">搜地</div>
				<div class="ct">
					<div class="top">
						<Select v-model="formItem.province" style="width: 100px" size="small" placeholder="省份"
							@on-change="selectProvince">
							<Option v-for="item in landProvinceList" :value="item.areaId" :key="item.areaId">
								{{ item.name }}</Option>
						</Select>
						<Select v-model="formItem.city" style="width: 100px" size="small" placeholder="城市"
							@on-change="selectCity">
							<Option v-for="item in cityList" :value="item.areaId" :key="item.areaId">{{ item.name }}
							</Option>
						</Select>
						<Select v-model="formItem.region" style="width: 100px" size="small" disabled clearable
							filterable placeholder="行政区">
							<Option v-for="item in regionList" :value="item.areaId" :key="item.areaId">{{ item.name }}
							</Option>
						</Select>
						<div class="select-box">
							<Select v-model="formItem.landUse" style="width: 100px" size="small" multiple
								@on-change="langChange" placeholder>
								<Option v-for="item in filterLandUse" :value="item.id" :key="item.id">{{ item.name }}
								</Option>
							</Select>
							<div class="place">土地用途</div>
						</div>
						<Select v-model="formItem.dealState" style="width: 100px" size="small" placeholder="交易状态">
							<Option v-for="item in dealState" :value="item.id" :key="item.id">{{ item.name }}</Option>
						</Select>
						<!-- <Select v-for="(item,key) in options" :key="key" v-model="item.value" style="width:100px" size="small" :placeholder="item.txt"
                            @on-change="optionSelect(item)">
                            <Option v-for="item in item.arr" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>-->
					</div>
					<input type="text" placeholder="请输入关键词（土地名称）" v-model="formItem.searchVal" />
				</div>
				<div class="right search-btn searchBtn" data-id="1" @click="search">立即搜索</div>
			</div>

			<div class="tab">
				<div class="tab-item" :class="{ active: item.value == tabsIndex }" v-for="(item, index) in tabs"
					:key="index" @click="changeTabs(index)">
					{{ item.name }}
				</div>

				<RadioGroup v-model="optionIndex" class="option-box">
					<div style="margin-right: 10px">
						<DownloadBtn :id="1006" @exportData="exportData" :type=1
							:style="{ 'background-color': 'rgba(241, 110, 113, 1)' }"></DownloadBtn>
					</div>
					<Radio label="1">全部</Radio>
					<Radio label="2">
						<div class="push-btn" @click.stop="showPushModal">我的推送</div>
					</Radio>
				</RadioGroup>
			</div>
			<div class="choose-box" v-if="this.tabsIndex != 2 && this.optionIndex == 2">
				<div class="title">推送列表：</div>
				<div class="des">
					<Tag type="border" :class="{ active: item.id == pushItem.id }" v-for="(item, index) in pushList"
						:key="index" @click.native="changePush(item)">
						<span class="tabContent">{{ item.pushName }}</span>
					</Tag>
				</div>
			</div>
			<div class="table-box">
				<Table :columns="
            tabsIndex == 0
              ? transferColumns
              : tabsIndex == 1
              ? publicityColumns
              : followColumns
          " :data="tableData" border max-height="600" class="table" tooltip-theme="light"
					@on-cell-click="tableCellClick" :loading="loading"></Table>
				<Page :total="total" show-elevator show-sizer @on-page-size-change="pageSizeChange"
					@on-change="pageChange" />
			</div>
		</div>
		<pushModal></pushModal>
		<addProjectModal :id="addProjectId" :address="address" type="place" :selfProjectName='projectName'></addProjectModal>
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import pushModal from "@/components/modal/pushModal";
	import tableBtn from "@/components/insight/TableBtn";
	import addProjectModal from "@/components/modal/addProject";
	// import BottomBar from "@/components/BottomBar";
	// import HorizenTable from "@/components/insight/HorizenTable";
	import DownloadBtn from "@/components/DownloadBtn";

	import {
		getpush
	} from "@/api/myPush";
	import {
		publicitySearch,
		transferSearch,
		followList,
		pushPublicitySearch,
		pushTransferSearch,
	} from "@/api/placeMarket";
	import {
		getLandSingleSelectCity,
		getLandSingleSelectRegion,
	} from "@/api/public";
	import {
		followPlace
	} from "@/api/placeMarket";
	import {
		addCityProject
	} from "@/api/projectAnalysis";
	import {
		downloadData,
		formatJson
	} from "@/utils";
	import dataReports from "@/mixins/dataReports";

	export default {
		name: "placeSearch",
		components: {
			pushModal,
			addProjectModal,
			DownloadBtn,
			// BottomBar,
			// HorizenTable
		},
		mixins: [dataReports],
		data() {
			return {
				pushModal: false,
				tabsIndex: 0,
				tabs: [{
						name: "最新出让",
						value: 0,
					},
					{
						name: "最新成交",
						value: 1,
					},
					{
						name: "我的关注",
						value: 2,
					},
				],

				cityList: [],
				regionList: [],

				options: {
					province: {
						txt: "省份",
						value: "",
						arr: [],
					},
					city: {
						txt: "城市",
						value: "",
						arr: [],
					},
					town: {
						txt: "行政区",
						value: "",
						arr: [],
					},
					landUse: {
						txt: "土地用途",
						value: "",
						arr: [],
					},
					dealState: {
						txt: "交易状态",
						value: "",
						arr: [],
					},
				},

				formItem: {},
				pageSize: 10,
				currentIndex: 1,
				pushList: [],
				pushItem: null,

				transferColumns: [{
						title: "",
						key: "pinggu",
						align: "center",
						width: 100,
						render: (h, params) => {
							return h(tableBtn, {
								props: {
									item: params.row,
								},
								on: {
									click: (params) => {
										this.handleAddStar(params);
									},
									txtClick: (params) => {
										this.handleAddProject(params);
									},
								},
							});
						},
						renderHeader: (h, index) => {
							return h("div", "");
						},
					},
					{
						title: "地块名称",
						key: "ppbm",
						align: "center",
						width: 300,

						tooltip: true,
					},
					{
						title: "地块位置",
						key: "zdzl",
						align: "center",
						"min-width": 200,

						tooltip: true,
					},
					{
						title: "城市",
						key: "cs",
						"min-width": 50,
						align: "center",
					},
					{
						title: "土地用途",
						key: "tdyt",
						align: "center",
						"min-width": 50,
					},
					{
						title: "土地面积",
						key: "jsydmj",
						align: "center",
						"min-width": 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "土地面积"), h("div", "（㎡）")]);
						},
						render: (h, params) => {
							return h(
								"span",
								params.row.jsydmj == "null" || params.row.jsydmj == "" ?
								"" :
								parseFloat(params.row.jsydmj).toFixed(2)
							);
						},
					},
					{
						title: "规划建筑面积",
						key: "ghjzmj",
						align: "center",
						"min-width": 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "规划建筑面积"), h("div", "（㎡）")]);
						},
						render: (h, params) => {
							return h(
								"span",
								params.row.ghjzmj == "null" || params.row.ghjzmj == "" ?
								"" :
								parseFloat(params.row.ghjzmj).toFixed(2)
							);
						},
					},
					{
						title: "容积率",
						key: "rjljsz",
						align: "center",
						"min-width": 50,
						render: (h, params) => {
							return h(
								"span",
								params.row.rjljsz == "" ? params.row.rjlxy : params.row.rjljsz
							);
						},
					},
					{
						title: "出让方式",
						key: "crfsCr",
						align: "center",
						"min-width": 140,
					},
					// {
					//     title: '截止日期',
					//     key: 'zpgjzsj',
					//     align: 'center',
					//     "width": 140,
					// },
					{
						title: "起始价",
						key: "qsjms",
						align: "center",
						renderHeader: (h, index) => {
							return h("div", [h("div", "起始价"), h("div", "（万元）")]);
						},
						"min-width": 140,
						render: (h, params) => {
							return h(
								"span",
								params.row.qsjms == "null" || params.row.qsjms == "" ?
								"" :
								parseFloat(params.row.qsjms.replace("万元", "")).toFixed(2)
							);
						},
					},
					// {
					//     title: '成交价',
					//     key: 'cjjms',
					//     align: 'center',
					//     "width": 140,
					//    /* render: (h,params)=>{
					//         return h('span',
					//             params.row.cjjms=='null'?'--':parseFloat(params.row.cjjms).toFixed(2)
					//         )
					//     }*/
					// },
					// {
					//     title: '成交楼面价',
					//     key: 'cjlmj',
					//     align: 'center',
					//     "width": 140,
					//    /* render: (h,params)=>{
					//         return h('span',
					//             params.row.cjlmj=='null'?'--':parseFloat(params.row.cjlmj).toFixed(2)
					//         )
					//     }*/
					// },
					// {
					//     title: '溢价率',
					//     key: 'yjl',
					//     align: 'center',
					//     "width": 140,
					//     render: (h,params)=>{
					//         return h('span',
					//             (params.row.yjl=='null' || parseInt(params.row.yjl)==0)?'':parseFloat(params.row.yjl*100)+"%"
					//         )
					//     }
					// },
					// {
					//     title: '受让单位',
					//     key: 'sldw',
					//     align: 'center',
					//     "width": 140,
					//     "tooltip":true,
					// },
					{
						title: "发布时间",
						key: "fbsjCr",
						align: "center",
						"min-width": 140,
					},
				],
				publicityColumns: [{
						title: "",
						key: "pinggu",
						align: "center",
						width: 100,
						render: (h, params) => {
							return h(tableBtn, {
								props: {
									item: params.row,
								},
								on: {
									click: (params) => {
										this.handleAddStar(params);
									},
									txtClick: (params) => {
										this.handleAddProject(params);
									},
								},
							});
						},
						renderHeader: (h, index) => {
							return h("div", "");
						},
					},
					{
						title: "地块名称",
						key: "ppbm",
						align: "center",
						width: 300,
						tooltip: true,
					},
					{
						title: "地块位置",
						key: "zdzl",
						align: "center",
						width: 200,
						tooltip: true,
					},
					{
						title: "城市",
						key: "cs",
						width: 100,
						align: "center",
					},
					{
						title: "土地用途",
						key: "tdyt",
						align: "center",
						width: 100,
					},
					{
						title: "土地面积",
						key: "jsydmj",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "土地面积"), h("div", "（㎡）")]);
						},
						render: (h, params) => {
							return h(
								"span",
								params.row.jsydmj == "null" || params.row.jsydmj == "" ?
								"" :
								parseFloat(params.row.jsydmj).toFixed(2)
							);
						},
					},
					{
						title: "规划建筑面积",
						key: "ghjzmj",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "规划建筑面积"), h("div", "（㎡）")]);
						},
						render: (h, params) => {
							return h(
								"span",
								params.row.ghjzmj == "null" || params.row.ghjzmj == "" ?
								"" :
								parseFloat(params.row.ghjzmj).toFixed(2)
							);
						},
					},
					{
						title: "容积率",
						key: "rjljsz",
						align: "center",
						width: 100,
					},
					{
						title: "出让方式",
						key: "crfsCr",
						align: "center",
						width: 140,
					},
					{
						title: "挂牌时间",
						key: "zpgqssj",
						align: "center",
						width: 140,
					},
					{
						title: "挂牌截止时间",
						key: "zpgjzsj",
						align: "center",
						width: 140,
					},
					{
						title: "起始价",
						key: "qsjms",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "起始价"), h("div", "（万元）")]);
						},
						render: (h, params) => {
							return h(
								"span",
								params.row.qsjms == "null" || params.row.qsjms == "" ?
								"" :
								parseFloat(params.row.qsjms.replace("万元", "")).toFixed(2)
							);
						},
						/* render: (h,params)=>{
						               return h('span',
						                   params.row.cjtddj=='null'?'--':parseFloat(params.row.cjtddj).toFixed(2)
						               )
						           }*/
					},
					{
						title: "成交价",
						key: "cjjms",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "成交价"), h("div", "（万元）")]);
						},
						render: (h, params) => {
							return h(
								"span",
								params.row.cjjms == "null" || params.row.cjjms == "" ?
								"" :
								parseFloat(params.row.cjjms).toFixed(2)
							);
						},
					},
					{
						title: "成交楼面价",
						key: "cjlmj",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "成交楼面价"), h("div", "（元/㎡）")]);
						},
						render: (h, params) => {
							return h(
								"span",
								params.row.cjlmj == "null" || params.row.cjlmj == "" ?
								"" :
								parseFloat(params.row.cjlmj).toFixed(0)
							);
						},
					},
					{
						title: "溢价率",
						key: "yjl",
						align: "center",
						width: 140,
					},
					{
						title: "受让单位",
						key: "sldw",
						align: "center",
						width: 140,
						tooltip: true,
					},
				],
				followColumns: [{
						title: "",
						key: "pinggu",
						align: "center",
						width: 100,
						render: (h, params) => {
							return h(tableBtn, {
								props: {
									item: params.row,
								},
								on: {
									click: (params) => {
										this.handleAddStar(params);
									},
									txtClick: (params) => {
										this.handleAddProject(params);
									},
								},
							});
						},
						renderHeader: (h, index) => {
							return h("div", "");
						},
					},
					{
						title: "交易状态",
						key: "stateText",
						align: "center",
						width: 100,
						tooltip: true,
					},
					{
						title: "地块名称",
						key: "ppbm",
						align: "center",
						width: 300,
						tooltip: true,
					},
					{
						title: "地块位置",
						key: "zdzl",
						align: "center",
						width: 200,
						tooltip: true,
					},
					{
						title: "城市",
						key: "cs",
						width: 90,
						align: "center",
					},
					{
						title: "土地用途",
						key: "tdyt",
						align: "center",
						width: 140,
					},
					{
						title: "土地面积",
						key: "jsydmj",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "土地面积"), h("div", "（㎡）")]);
						},
						render: (h, params) => {
							return h(
								"span",
								params.row.jsydmj == "null" || params.row.jsydmj == "" ?
								"" :
								parseFloat(params.row.jsydmj).toFixed(2)
							);
						},
					},
					{
						title: "规划建筑面积",
						key: "ghjzmj",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "规划建筑面积"), h("div", "（㎡）")]);
						},
						render: (h, params) => {
							return h(
								"span",
								params.row.ghjzmj == "null" || params.row.ghjzmj == "" ?
								"" :
								parseFloat(params.row.ghjzmj).toFixed(2)
							);
						},
					},
					{
						title: "容积率",
						key: "rjljsz",
						align: "center",
						width: 140,
					},
					{
						title: "出让方式",
						key: "crfsCr",
						align: "center",
						width: 140,
					},
					{
						title: "截止日期",
						key: "zpgjzsj",
						align: "center",
						width: 140,
					},
					{
						title: "起始价",
						key: "qsjms",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "起始价"), h("div", "（万元）")]);
						},
						render: (h, params) => {
							return h(
								"span",
								params.row.qsjms == "null" || params.row.qsjms == "" ?
								"" :
								parseFloat(params.row.qsjms).toFixed(2)
							);
						},
					},
					{
						title: "成交价",
						key: "cjjms",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "成交价"), h("div", "（万元）")]);
						},
						render: (h, params) => {
							return h(
								"span",
								params.row.cjjms == "null" || params.row.cjjms == "" ?
								"" :
								parseFloat(params.row.cjjms).toFixed(2)
							);
						},
						/*render: (h,params)=>{
						              return h('span',
						                  params.row.cjjms=='null'?'--':parseFloat(params.row.cjjms).toFixed(2)
						              )
						          }*/
					},
					{
						title: "成交楼面价",
						key: "cjlmj",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "成交楼面价"), h("div", "（元/㎡）")]);
						},
						render: (h, params) => {
							return h(
								"span",
								params.row.cjlmj == "null" || params.row.cjlmj == "" ?
								"" :
								parseFloat(params.row.cjlmj).toFixed(0)
							);
						},
					},
					{
						title: "溢价率",
						key: "yjl",
						align: "center",
						width: 140,
						// render: (h, params) => {
						//   return h('span',
						//     (params.row.yjl == '' || parseInt(params.row.yjl) == 0) ? '' : parseFloat(params.row.yjl * 100) + "%"
						//   )
						// }
					},
					{
						title: "受让单位",
						key: "sldw",
						align: "center",
						width: 140,
						tooltip: true,
					},
					{
						title: "挂牌时间",
						key: "zpgqssj",
						align: "center",
						width: 140,
					},
				],
				tableData: [],
				total: 0,
				loading: false,
				optionIndex: "1",

				addProjectId: -1,
				// 框选位置时传入的经纬度
				address: {},
				projectName:'',
			};
		},
		computed: {
			...mapState(["userId", "landUse", "dealState", "landProvinceList"]),
			filterLandUse() {
				let arr = [];
				let list = JSON.parse(JSON.stringify(this.landUse));
				list = list.slice(0, 4);
				list.unshift({
					id: 0,
					name: "全选",
				});
				// if(this.tabsIndex == 1) {
				// 	list.splice(list.length-1,1);
				// }
				if (list) arr = list;
				return arr;
			},
			showPushManage: {
				get() {
					return this.$store.state.modal.showPushManage;
				},
				set(newValue) {
					this.$store.state.modal.showPushManage = newValue;
				},
			},
			showAddProjectModal: {
				get() {
					return this.$store.state.modal.showAddProjectModal;
				},
				set(value) {
					this.$store.state.modal.showAddProjectModal = value;
				},
			},
		},
		watch: {
			optionIndex: function(val) {
				this.getList();
			},
		},
		beforeMount() {
			this.$Notice.destroy();
		},
		mounted() {
			this.initData();
			this.info1();
			this.$nextTick(()=>{
				document.getElementsByClassName('searchBtn')[0].addEventListener('click',()=>{this.$btnSysLog(1,0,{pageId:11,pageName:'地块搜索'})})
			})
		},
		destroyed(){
			document.getElementsByClassName('searchBtn')[0].addEventListener('click',()=>{this.$btnSysLog(1,0,{pageId:11,pageName:'地块搜索'})})
		},
		methods: {
			info1() {

			},
			initData() {
				// getCityQuery().then(res => {
				//
				// })
				// getLandUse().then(res => {
				// 	this.landUse = res;
				// })
				// getDealState().then(res => {
				// 	this.dealState = res;
				// })
				this.getPushList();
				this.getList(this.tabsIndex);
			},
			getPushList() {
				if (this.tabsIndex == 2) {
					this.getList();
					return;
				}
				getpush({
						userId: this.userId,
						pushType: this.tabsIndex + 1,
					})
					.then((res) => {
						if (res.length) {
							this.pushList = res;
							this.pushItem = res[0];
						} else {
							this.pushList = [];
						}
					})
					.finally(() => {
						this.getList();
					});
			},
			exportData() {
				const param = {
					province: "",
					city: "",
					region: "",
					sellWay: [],
					natureOfLandUse: [2, 3],
					size: 1000000000,
					current: 1,
				};
				let filterVal = [];
				let tHeader = [];
				let resData, tableData;
				if (this.tabsIndex == 0) {
					transferSearch(param)
						.then((data) => {
							tableData = data.records;
							this.transferColumns.forEach((item) => {
								tHeader.push(item.title);
								filterVal.push(item.key);
							});
							resData = formatJson(filterVal, tableData);
						})
						.finally(() => {
							downloadData(tHeader, resData, "最新出让");
						});
				} else if (this.tabsIndex == 1) {
					publicitySearch(param)
						.then((data) => {
							tableData = data.records;
							this.publicityColumns.forEach((item) => {
								tHeader.push(item.title);
								filterVal.push(item.key);
							});
							resData = formatJson(filterVal, tableData);
						})
						.finally(() => {
							downloadData(tHeader, resData, "最新成交");
						});
				} else {
					followList({
							size: 1000000000000,
							current: 1,
							type: 1,
						})
						.then((data) => {
							tableData = data.records;
							this.followColumns.forEach((item) => {
								tHeader.push(item.title);
								filterVal.push(item.key);
							});
							resData = formatJson(filterVal, tableData);
						})
						.finally(() => {
							downloadData(tHeader, resData, "我的关注");
						});
				}
			},
			changeTabs(index) {
				if (this.tabsIndex == index || this.loading) return;
				this.tabsIndex = index;
				this.currentIndex = 1;
				this.getPushList();
			},
			changePush(item) {
				if (this.pushItem.id == item.id) return;
				this.currentIndex = 1;
				this.pushItem = item;
				this.getList();
			},
			getSearchData() {
				let param = {
					province: "",
					city: "",
					region: "",
					sellWay: [],
					natureOfLandUse: [2, 3],
					size: this.pageSize,
					current: this.currentIndex,
				};
				if (this.optionIndex == 2 && this.tabsIndex != 2) {
					param = {
						size: this.pageSize,
						current: this.currentIndex,
						pushId: this.pushItem.id,
					};
				} else if (this.tabsIndex == 2) {
					param = {
						size: this.pageSize,
						current: this.currentIndex,
						type: 1,
					};
				}
				return param;
			},
			getList() {
				const param = this.getSearchData();
				this.loading = true;
				this.tableData = [];

				let listApi = "";

				if (this.tabsIndex == 0) {
					listApi = this.optionIndex == 1 ? transferSearch : pushTransferSearch;
				} else if (this.tabsIndex == 1) {
					listApi = this.optionIndex == 1 ? publicitySearch : pushPublicitySearch;
				} else {
					listApi = followList;
				}

				listApi(param)
					.then((data) => {
						this.total = data.total;
						this.tableData = data.records;
					})
					.finally(() => {
						this.loading = false;
					});
			},
			pageSizeChange(size) {
				this.currentIndex = 1;
				this.pageSize = size;
				this.getList();
			},
			pageChange(size) {
				this.currentIndex = size;
				this.getList();
			},
			switchThead(value) {
				this.theadType = value;
			},
			showPushModal() {
				let routeData = this.$router.resolve({
					path: "/PersonalCenter/MyPush",
				});
				window.open(routeData.href, "_blank");
			},

			selectProvince(item) {
				getLandSingleSelectCity({
					id: item,
				}).then((data) => {
					this.cityList = data;
					this.formItem.city = "";
					this.regionList = [];
					this.formItem.region = "";
					// this.getList();
				});
			},
			selectCity(item) {
				getLandSingleSelectRegion({
					id: item,
				}).then((data) => {
					this.regionList = data;
					this.formItem.region = "";
					// this.getList();
				});
			},
			langChange(arr) {
				let formItem = this.formItem;
				// 全选了
				if (arr.indexOf(0) > -1) {
					formItem.landUse = this.filterLandUse.map((item) => item.id);
				}
			},

			search() {
				let formItem = this.formItem;

				this.$router.push({
					path: `/Insight/PlaceOptions`,
					query: {
						province: formItem.province,
						city: formItem.city,
						region: formItem.region,
						landUse: JSON.stringify(formItem.landUse),
						dealState: formItem.dealState,
						searchVal: formItem.searchVal,
					},
				});
			},

			tableCellClick(row, column, data, event) {
				if (column.key == "ppbm") {
					this.gotoDetail(row);
				}
			},
			gotoDetail(params) {
				let info = params;

				let routeData = this.$router.resolve({
					path: `/Insight/PlaceDetail`,
					query: {
						id: info.id,
						type: "option",
						// type: this.tabsIndex == 2 ? 'option' : 'search',
						// methods: this.tabsIndex + 1
					},
				});
				window.open(routeData.href, "_blank");
			},
			handleAddStar(item) {
				followPlace({
					state: item.star ? -1 : 1,
					type: 1,
					unionId: item.id,
				}).then((data) => {
					if (item.star) {
						// this.$msg.success("取消关注成功");

						this.$msg.error({
							type: "remind",
							code: 4035
						});

						item.star = false;
					} else {
						// this.$msg.success("关注成功");
						this.$msg.error({
							type: "remind",
							code: 4036
						});

						item.star = true;
					}
				});
			},
			handleAddProject(data) {
				this.showAddProjectModal = true;
				this.addProjectId = data.id;
				this.address.lng = data.jd;
				this.address.lat = data.wd;
				this.projectName = data.ppbm
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "~@/style/common.scss";

	.page {
		width: 100%;
		min-height: calc(100vh - 80px);
		margin: 0 auto;
		padding-top: 20px;
		background: #fff;
		overflow: hidden;

		.page-ct {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			min-width: 1200px;
			padding: 0 20px;
		}

		.page-title {
			display: inline-block;
			position: relative;
			margin: 100px auto 0;
			font-size: 36px;
			font-style: italic;
			color: rgba(67, 115, 232, 1);

			&::after {
				position: absolute;
				height: 2px;
				width: 100%;
				left: 0;
				bottom: -10px;
				background-color: #4373e8;
				content: "";
			}
		}

		.search-box {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 890px;
			height: 98px;
			margin-top: 40px;
			background: rgba(255, 255, 255, 1);
			border: 1px solid rgba(221, 221, 221, 1);
			box-shadow: 0px 10px 20px 0px rgba(41, 53, 92, 0.1);
			border-radius: 10px;
			// overflow: hidden;

			.left {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 108px;
				height: 100%;
				color: #999;
				font-size: 18px;
				border-right: 1px solid #e4e4e4;
			}

			.ct {
				flex-grow: 1;

				.top {
					@include flex(space-around);
					height: 40px;
					border-bottom: 1px solid #eee;

					/deep/ .ivu-select-selection {
						border: none;

						.ivu-select-placeholder {
							color: #000;
						}
					}
				}

				input {
					width: 100%;
					height: 60px;
					padding-left: 20px;
					font-size: 14px;
					border: none;
					background: rgba(249, 249, 249, 1);
				}
			}

			.right {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 150px;
				height: 98px;
				border-radius: 0 10px 10px 0;
				background: rgba(97, 109, 255, 1);
				box-shadow: 0px 10px 20px 0px rgba(41, 53, 92, 0.1);
				color: #fff;
				font-size: 16px;
				cursor: pointer;
			}

			.select-box {
				position: relative;

				/deep/ .ivu-tag {
					display: none;
				}

				.place {
					position: absolute;
					top: 3px;
					left: 10px;
					pointer-events: none;
				}
			}
		}

		.tab {
			display: flex;
			align-items: center;
			height: 46px;
			width: 100%;
			margin-top: 50px;
			color: #666;
			font-size: 16px;
			border: 1px solid #e3e3e5;
			background-color: #fbfcff;

			&-item {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 126px;
				height: 100%;
				cursor: pointer;

				&.active {
					color: #00b6ff;

					&::after {
						position: absolute;
						width: 100%;
						height: 3px;
						left: 0;
						bottom: 0;
						background-color: #00b6ff;
						content: "";
					}
				}
			}

			.push-btn {
				@include flex;
				width: 82px;
				height: 28px;
				margin: 0 15px 0 auto;
				color: #666;
				font-size: 14px;
				background: rgba(255, 255, 255, 1);
				border: 1px solid rgba(234, 234, 234, 1);
				border-radius: 5px;
				cursor: pointer;
			}

			.option-box {
				@include flex;
				margin-left: auto;

				.ivu-radio-wrapper {
					@include flex;
				}
			}
		}

		.choose-box {
			@include flex(flex-start);
			width: 100%;
			min-height: 40px;
			padding: 0 5px;
			background-color: #f0f0f0;

			.title {
				color: #666;
			}

			.des {
				@include flex(flex-start, flex-start);

				/deep/ .ivu-tag {
					cursor: pointer;

					&.active {
						.ivu-tag-text {
							color: #2d8cf0;
						}
					}
				}
			}
		}

		.table-box {
			width: 100%;

			.ivu-page {
				position: relative;
				margin-top: 15px;
				text-align: right;
			}
		}
	}
</style>
