<template>
	<div>
		<Modal class="add-modal" :mask-closable="false" footer-hide v-model="showAddProjectModal"
			class-name="vertical-center-modal">
			<p class="head">
				<span class="icon"></span>
				<span class="text">新建项目</span>
			</p>
			<div class="content">
				<span class="label">项目名称</span>
				<Input v-model="projectName" :maxlength="20" style="width: 316px" placeholder="请输入项目名称，不超20字"></Input>
			</div>
			<Button type="primary" @click="submit">确定</Button>
		</Modal>
		<mapModal :id="id" :address="address" :projectName="projectName" :type="type"></mapModal>
	</div>
</template>

<script>
	import mapModal from "@/components/modal/mapModal";
	export default {
		name: "addModal",
		components: {
			mapModal,
		},
		props: {
			id: Number,
			type: String,
			zdyjson: String,
			address: Object,
			selfProjectName: String,
		},
		data() {
			return {
				projectName: "",
			};
		},
		computed: {
			showAddProjectModal: {
				get() {
					return this.$store.state.modal.showAddProjectModal;
				},
				set(value) {
					this.$store.state.modal.showAddProjectModal = value;
				},
			},
			showMapModal: {
				get() {
					return this.$store.state.modal.showMapModal;
				},
				set(newValue) {
					this.$store.state.modal.showMapModal = newValue;
				},
			},
		},
		watch: {
			selfProjectName(val){
				this.projectName = val
			}
		},

		methods: {
			submit() {
				if (!this.projectName) {
					// this.$Message.warning("请输入项目名称");
					this.$msg.error({
						type: "remind",
						code: 4024,
					});
					return;
				}

				this.showAddProjectModal = false;
				this.showMapModal = true;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.add-modal {
		position: relative;
		text-align: center;

		& /deep/ .vertical-center-modal {
			display: flex;
			align-items: center;
			justify-content: center;

			.ivu-modal {
				top: 0;
			}

			.content {
				margin-top: 30px;
				margin-bottom: 30px;
				display: flex;
				justify-content: center;
				align-items: center;

				.label {
					margin-right: 20px;
				}
			}
		}

		.head {
			width: 100%;
			display: flex;
			align-items: center;

			.icon {
				display: inline-block;
				width: 4px;
				height: 23px;
				background-color: #00b6ff;
				position: relative;

				&::after {
					content: "";
					border: 4px solid transparent;
					border-left-color: #00b6ff;
					position: absolute;
					top: 50%;
					right: -7px;
					margin-top: -4px;
				}
			}

			.text {
				margin-left: 6px;
				font-size: 18px;
				font-weight: bold;
				line-height: 20px;
				color: #333;
			}
		}

		.ivu-btn {
			width: 114px;
		}
	}
</style>
