import { post, get } from '@/utils/http'

export function getTdxz (params) {
  return get({ url: '/service-insight/land_dim/landUse', params })
}
export function getWylx (params) {
  return get({ url: '/service-insight/dim_city/propertyType', params })
}
export function save (params) {
  return post({ url: '/service-insight/my_info/save_push', params })
}
export function getData (params) {
  return get({ url: '/service-insight/my_info/get_push_by_id', params })
}
export function getpush (params) {
  return post({ url: '/service-insight/my_info/get_push', params })
}
export function deletepush (params) {
  return get({ url: '/service-insight/my_info/delete_push', params })
}
export function pushCjj (params) {
  return get({ url: '/service-insight/my_info/push_cjj', params })
}
export function pushLmj (params) {
  return get({ url: '/service-insight/my_info/push_lmj', params })
}
export function pushTdmj (params) {
  return get({ url: '/service-insight/my_info/push_tdmj', params })
}

